
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Gallery extends Vue {

  @Prop({
    type: Array,
    required: true
  })
  images!: Array<string>;

  fullscreen = false;
  currentImageIndex = 0;

  setCurrentImage (imageIndex: number): void {
    this.currentImageIndex = imageIndex;
  }

  toggleFullscreen (fullscreen?: boolean): void {
    this.fullscreen = typeof fullscreen === 'boolean' ? fullscreen : !this.fullscreen;
  }
}
