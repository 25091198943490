
import { Component, Prop, Vue } from 'vue-property-decorator';

// Models
import ProductVariantOptionModel from '@/models/product-variant-option.model';

@Component
export default class VariantSelector extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  variant!: ProductVariantOptionModel;

  @Prop({
    type: String,
    default: undefined
  })
  value!: string;

  handleSelection (value: string): void {
    this.$emit('input', value);
  }
}
