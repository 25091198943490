
import { Component, Vue, Watch } from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import Gallery from '@/components/gallery.component.vue';
import LoadingOverlay from '@/components/loading-overlay.component.vue';
import ProductTile from '@/components/product-tile.component.vue';
import QuantityPicker from '@/components/quantity-picker.component.vue';
import VariantSelector from '@/components/variant-selector.component.vue';

// Models
import CartModel from '@/models/cart.model';
import ProductModel from '@/models/product.model';
import ProductVariantModel from '@/models/product-variant.model';

@Component({
  components: {
    Gallery,
    LoadingOverlay,
    ProductTile,
    QuantityPicker,
    VariantSelector
  }
})
export default class ProductDetails extends Vue {
  selectedQuantity = 1;
  selectedVariants: string[] = [];

  addingProduct = false;
  overlayMessage = '';
  showOverlay = false;
  showError = false;

  get amountInCart(): number {
    let result = 0;
    const cart: CartModel = this.$store.state.cart.cart;

    if (cart) {
      const cartEntry = cart.entries.find(entry => entry.id === this.selectedVariant?.id);

      if (cartEntry) {
        result = cartEntry.quantity;
      }
    }

    return result;
  }

  get loading(): boolean {
    return this.$store.state.productDetails.loading
  }

  get product(): ProductModel | null {
    return this.$store.state.productDetails.product;
  }

  get relatedProducts(): Array<ProductModel> {
    return this.$store.state.productDetails.relatedProducts;
  }

  get selectedVariant(): ProductVariantModel | null {
    if (!this.product) {
      return null;
    }

    return this.product.variants.find(variant => {
      return variant.variantIdentifiers.every(identifier => this.selectedVariants.includes(identifier.value))
    }) || null;
  }

  get showPurchaseButtons(): boolean {
    if (!this.selectedVariant) {
      return false;
    }

    return this.selectedVariant.availableForSale &&
        this.selectedVariant?.availableQuantity > this.amountInCart;
  }

  get showVariantSelectors(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product?.variants.length > 1;
  }

  protected created(): void {
    this.loadProductData(this.$route.params.id);
  }

  @Watch('$route.params.id')
  loadProductData(productId: string) {
    // If a product was already loaded, scroll to top first
    if (this.product !== null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    this.$store.dispatch(`productDetails/loadProductDetails`, {
      productId: productId
    })
      .then(() => {
        // Select initial variants
        this.product?.variantOptions
            .forEach(variantOption => this.selectedVariants.push(variantOption.options[0].value));

        track(TrackingEventType.ViewContent, {
          content_category: RouteNames.ProductDetails,
          content_ids: [
            this.$route.params.id
          ],
          content_name: this.product?.name,
          content_type: 'product',
        });
      });
  }

  addProductToCart() {
    if (this.selectedVariant !== null) {
      this.addingProduct = true;
      this.showOverlay = true;

      track(TrackingEventType.AddToCart, {
        content_ids: [
          this.$route.params.id
        ],
        content_name: this.product?.name,
        content_type: 'product',
        contents: [
          {
            id: this.$route.params.id,
            quantity: this.selectedQuantity
          }
        ]
      });

      this.$store.dispatch('cart/addProductToCart', {
        productId: this.selectedVariant.id,
        quantity: this.selectedQuantity
      })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.addingProduct = false;
        });
    }
  }

  checkoutWithProduct() {
    if (this.selectedVariant !== null) {
      this.addingProduct = true;
      this.showOverlay = true;

      track(TrackingEventType.InitiateCheckout, {
        content_category: RouteNames.ProductDetails,
        content_ids: [
          this.$route.params.id
        ],
        contents: [{
          id: this.$route.params.id,
          quantity: this.selectedQuantity
        }],
        num_items: 1
      });

      this.$store.dispatch('cart/checkoutWithProduct', {
        productId: this.selectedVariant.id,
        quantity: this.selectedQuantity
      })
    }
  }

  closeOverlay() {
    this.showOverlay = false;
    this.showError = false;
  }

  trackVariantSelection() {
    track(TrackingEventType.CustomizeProduct)
  }
}
