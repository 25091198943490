
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class QuantityPicker extends Vue {

  @Prop({
    type: Number,
    required: true
  })
  quantity!: number;

  @Prop({
    type: Number,
    default: 100
  })
  maxQuantity!: number;

  internalQuantity = 1;

  @Watch('quantity')
  quantityChanged() {
    this.internalQuantity = this.quantity;
  }

  protected created() {
    this.quantityChanged();
  }

  addClicked() {
    if (this.internalQuantity < this.maxQuantity) {
      this.$emit('change', this.internalQuantity + 1);
    }
  }

  removeClicked() {
    if (this.internalQuantity > 1) {
      this.$emit('change', this.internalQuantity - 1);
    }
  }

  inputChanged() {
    if (this.internalQuantity !== this.quantity) {
      // Check for invalid values
      if (this.internalQuantity <= 0) {
        this.internalQuantity = 1;
      }
      if (this.internalQuantity > this.maxQuantity) {
        this.internalQuantity = this.maxQuantity;
      }

      this.$emit('change', this.internalQuantity);
    }
  }

}
